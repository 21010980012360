<template>
  <div v-if="product.variation_theme">
    <v-row
      v-for="(variant, index) in product.variants"
      :key="'variant_form_row_' + index"
      no-gutters>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 11">
        <div class="wrapper">
          <div v-if="$vuetify.breakpoint.smAndUp" class="row-number">{{ index + 1 }}.</div>
          <div class="wrapper-content">
            <v-row no-gutters>
              <v-col cols="1" v-if="$vuetify.breakpoint.xsOnly">
                <div class="row-number">{{ index+1 }}.</div>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 11 : 4">
                <v-text-field
                  class="form-input"
                  v-model="variant.name"
                  :label="variantTypeName[product.variation_theme]"
                  :rules="[ isRequired ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <v-text-field
                  class="form-input"
                  v-model="variant.bar_code"
                  label="Codigo de barras"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <v-select
                  class="form-input"
                  v-model="variant.condition"
                  :items="conditions"
                  item-text="name"
                  item-value="key"
                  label="Estado"
                  :rules="[ isRequired ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <v-text-field
                  class="form-input"
                  v-model.number="variant.quantity_in_stock"
                  label="Cantidad"
                  :rules="[ isRequiredNumeric ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
                <v-text-field
                  class="form-input"
                  v-model.number="variant.price"
                  label="Precio"
                  :rules="[ isRequired, isNumeric ]"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <product-image class="mb-6 form-input" :product="variant" />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col :class="['delete-col', { 'text-center': $vuetify.breakpoint.smAndUp, 'text-right': $vuetify.breakpoint.xsOnly }]" :cols="$vuetify.breakpoint.xsOnly ? 12 : 1">
        <v-btn icon @click="deleteVariant(index)">
          <v-icon light>delete_outline</v-icon>
        </v-btn>
      </v-col>
      <v-col class="form-divider-container" cols="12" >
        <hr class="form-divider">
      </v-col>
    </v-row>
    <div class="form-add-row">
      <span class="add-row-text" @click="addVariant">+ agregar {{ variantTypeName[product.variation_theme] }}</span>
    </div>
  </div>
</template>
<script>
import ProductImage from '@/components/Product/ProductImage'
import { VARIANT, VARIANT_TYPE_NAME } from '@/forms/product'
import { PRODUCT_CONDITIONS } from '@/shared/statuses'
import { isRequired, isNumeric, isRequiredNumeric } from '@/shared/validations'
import { cloneObject } from '@/shared/clone'

export default {
  components: {
    ProductImage
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          variation_theme: '',
          variants: []
        }
      }
    }
  },
  data () {
    return {
      conditions: PRODUCT_CONDITIONS,
      variantTypeName: VARIANT_TYPE_NAME
    }
  },
  methods: {
    isRequired,
    isNumeric,
    isRequiredNumeric,
    addVariant () {
      this.product.variants.push(cloneObject(VARIANT))
      this.$forceUpdate()
    },
    deleteVariant (index) {
      this.product.variants.splice(index, 1)
      this.$forceUpdate()
      if (this.product.variants.length === 0) {
        this.$emit('allDeleted')
      }
    }
  }
}
</script>
<style scoped>
.wrapper {
  display: flex;
  height: 100%;
}
.wrapper-content {
  flex-grow: 1;
  max-width: 94%;
}
.row-number {
  font-size: 1.563rem;
  font-weight: 500;
  margin-top: 0.125rem;
  margin-right: 1rem;
}
.form-input {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.delete-col {
  margin-top: 0.125rem;
}
.form-divider-container {
  padding-top: 0.375rem;
  padding-bottom: 1.25rem;
}
.form-divider {
  left: 0;
  right: 0;
  background-color: #DDDDDD;
  border: none;
  height: 0.063rem;
}
.form-add-row {
  font-size: 0.875rem;
  color: var(--app-button-bg-color);
  text-align: left;
}
.add-row-text {
  cursor: pointer;
  text-transform: lowercase;
}
@media only screen and (max-width: 37.5rem) {
  .wrapper-content {
    max-width: 100%;
  }
  .delete-col {
    margin-top: -0.75rem;
  }
  .row-number{
    margin-right: 0;
  }
  .form-input {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .form-divider {
    position: absolute;
  }
}
@media only screen and (max-width: 20.625rem) {
  .row-number{
    font-size: 1.25rem;
    margin-top: 0.25rem;
    margin-right: 0;
  }
}
</style>
