<template>
  <v-row no-gutters>
    <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
      <v-text-field
        class="form-input-right"
        v-model="product.bar_code"
        label="Código de barras"
        :rules="[ isRequired ]"
        outlined
        dense
      />
    </v-col>
    <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
      <v-select
        class="form-input-left"
        v-model="product.status"
        :items="statuses"
        item-text="name"
        item-value="key"
        label="Estado"
        :rules="[ isRequired ]"
        outlined
        dense
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="product.name"
        label="Nombre"
        :rules="[ isRequired ]"
        outlined
        dense
      />
    </v-col>
    <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
      <v-text-field
        class="form-input-right"
        v-model="product.brand"
        label="Marca"
        :rules="[ isRequired ]"
        outlined
        dense
      />
    </v-col>
    <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
      <v-text-field
        class="form-input-left"
        v-model="product.manufacturer"
        label="Fabricante"
        outlined
        dense
      />
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="product.description"
        label="Descripción"
        rows="2"
        :rules="[ isRequired ]"
        outlined
        dense
      />
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="product.uses_and_care"
        label="Usos y cuidados"
        rows="2"
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>
<script>
import { isRequired, isNumeric } from '@/shared/validations'
import { STATUSES } from '@/shared/statuses'

export default {
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          bar_code: null,
          status: null,
          name: null,
          brand: null,
          manufacturer: null,
          description: null,
          uses_and_care: null
        }
      }
    }
  },
  data () {
    return {
      statuses: STATUSES
    }
  },
  methods: {
    isRequired,
    isNumeric
  }
}
</script>
<style scoped>
.form-input-left {
  padding-left: 0.5rem;
}
.form-input-right {
  padding-right: 0.5rem;
}
.form-add-row {
  font-size: 0.875rem;
  color: var(--app-button-bg-color);
  text-align: left;
}
.add-row-text {
  cursor: pointer;
}
.formula-container {
  padding-bottom: 1rem;
}
.formula-title {
  font-size: 0.938rem;
  font-weight: 500;
}
.range-chip {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}
.modal-title {
 text-transform: lowercase;
}
.modal-title::first-letter {
 text-transform: uppercase;
}
.modal-description {
  text-align: left;
  text-transform: lowercase;
  font-size: 0.938rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.modal-description::first-letter {
 text-transform: uppercase;
}
.modal-form {
  padding-top: 1rem;
}
.modal-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media only screen and (max-width: 37.5rem) {
  .form-input-left {
    padding-left: 0rem;
  }
  .form-input-right {
    padding-right: 0rem;
  }
}
</style>
