<template>
  <div>
    <v-row no-gutters>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <v-select
          v-model="product.requires_formula"
          :items="requiresFormula"
          item-text="name"
          item-value="key"
          label="Maneja fórmula"
          @change="changedRequiresFormula"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <div v-if="product.requires_formula">
      <v-row  no-gutters class="formula-container" v-for="formula in formulaValues" :key="'formula_details_'+formula.name">
        <v-col>
          <span class="formula-title">{{ formulaDetails[formula.name].name }}</span>
          <div>
            <v-chip class="range-chip" v-for="(value, index) in getProductFormulaValues(formula.name)" :key="'modal_chip_' + formula.name + '_' + index" close small
              @click:close="removeRange(formula.name, value)">
              <span>{{ formatFormulaValue(value.min, formula.name) }}</span>
              <span v-if="value.max">, {{ formatFormulaValue(value.max, formula.name) }}</span>
            </v-chip>

            <v-chip class="range-chip" small outlined @click="openFormulaForm(formula.name)"
              :style="{
                color: 'var(--app-button-bg-color)'
              }">
              <v-icon small left>
                add
              </v-icon>
              Agregar
            </v-chip>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="openModal" persistent fullscreen>
      <v-card v-if="modalDetails">
        <v-card-title>
          <span class="modal-title">{{ modalDetails.title }}</span>
        </v-card-title>
        <div class="modal-description">{{ modalDetails.description }}</div>
        <v-card-text>
          <v-row no-gutters class="text-center">
            <v-col xl="10" lg="12" md="12" sm="12" class="modal-form">
              <product-formula-form :modalDetails="modalDetails" :formulaValue="selectedFormulaValue"></product-formula-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn
            class="modal-button"
            depressed
            large
            :style="{
              backgroundColor: 'transparent',
              color: 'rgba(0,0,0,0.6)',
              fontSize: '0.75rem',
            }"
            @click="closeFormulaForm">Cancelar
          </v-btn>
          <v-btn
            class="modal-button"
            depressed
            large
            :style="{
              backgroundColor: 'var(--app-button-bg-color)',
              color: '#FFFFFF',
              fontSize: '0.75rem',
            }"
            @click="finish">Terminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { isRequired, isNumeric } from '@/shared/validations'
import { getFormulaValue, PRODUCT_REQUIRES_FORMULA, FORMULA_VALUES, FORMULA_DETAILS } from '@/shared/formula'
import { cloneObject } from '@/shared/clone'

import ProductFormulaForm from '@/components/Product/ProductFormulaForm'

export default {
  components: {
    ProductFormulaForm
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          quantity_in_stock: null,
          price: null,
          condition: null,
          requires_formula: false,
          available_formulas: []
        }
      }
    }
  },
  data () {
    return {
      requiresFormula: PRODUCT_REQUIRES_FORMULA,
      formulaValues: FORMULA_VALUES,
      formulaDetails: FORMULA_DETAILS,
      openModal: false,
      selectedFormulaValue: null,
      modalDetails: null
    }
  },
  methods: {
    isRequired,
    isNumeric,
    formatFormulaValue (value, formulaName) {
      if (value === 0) {
        return value
      }

      if (formulaName === 'axis') {
        return value + '°'
      }

      const formattedValue = getFormulaValue(value, 2)

      return value > 0 ? '+' + formattedValue : formattedValue
    },
    getFormulaIndexByName (formulaName) {
      return this.product.available_formulas.findIndex(formula => {
        return formula.name === formulaName
      })
    },
    getProductFormulaValues (formulaName) {
      const availableFormulas = this.product.available_formulas
      if (!availableFormulas || availableFormulas.length === 0) {
        return []
      }

      const index = this.getFormulaIndexByName(formulaName)

      return index >= 0 ? availableFormulas[index].values : []
    },
    changedRequiresFormula () {
      this.formulaValues = cloneObject(FORMULA_VALUES)
    },
    openFormulaForm (formulaName) {
      this.modalDetails = this.formulaDetails[formulaName]
      this.selectedFormulaValue = {
        name: formulaName,
        values: cloneObject(this.getProductFormulaValues(formulaName))
      }

      this.openModal = true
    },
    setProductFormula (formula) {
      const availableFormulas = this.product.available_formulas
      if (!availableFormulas || availableFormulas.length === 0) {
        this.product.available_formulas = [formula]
        return
      }

      const index = this.getFormulaIndexByName(formula.name)
      if (index === -1) {
        this.product.available_formulas.push(formula)
        return
      }

      this.product.available_formulas[index] = formula
    },
    finish () {
      this.setProductFormula(this.selectedFormulaValue)
      this.openModal = false
    },
    closeFormulaForm () {
      this.openModal = false
    },
    removeRange (formulaName, value) {
      const index = this.getFormulaIndexByName(formulaName)
      const availableFormulas = this.product.available_formulas

      const indexValue = availableFormulas[index].values.indexOf(value)
      this.product.available_formulas[index].values.splice(indexValue, 1)

      if (this.product.available_formulas[index].values.length === 0) {
        this.product.available_formulas.splice(index, 1)
      }
    }
  }
}
</script>
<style scoped>
.form-input-left {
  padding-left: 0.5rem;
}
.form-input-right {
  padding-right: 0.5rem;
}
.form-add-row {
  font-size: 0.875rem;
  color: var(--app-button-bg-color);
  text-align: left;
}
.add-row-text {
  cursor: pointer;
}
.formula-container {
  padding-bottom: 1rem;
}
.formula-title {
  font-size: 0.938rem;
  font-weight: 500;
}
.range-chip {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}
.modal-title {
 text-transform: lowercase;
}
.modal-title::first-letter {
 text-transform: uppercase;
}
.modal-description {
  text-align: left;
  text-transform: lowercase;
  font-size: 0.938rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.modal-description::first-letter {
 text-transform: uppercase;
}
.modal-form {
  padding-top: 1rem;
}
.modal-button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media only screen and (max-width: 37.5rem) {
  .form-input-left {
    padding-left: 0rem;
  }
  .form-input-right {
    padding-right: 0rem;
  }
}
</style>
