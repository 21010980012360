<template>
  <form-container class="form-container"
    :title="title"
    :description="description"
    :buttonText="buttonText"
    :useLoading="useLoading"
    @submit="next">
    <template v-slot:header class="form-content">
      <v-row no-gutters>
        <v-col class="text-left" v-if="tabIndex > 0">
          <div class="back-container" @click="back">
            <v-icon class="back-icon">mdi-keyboard-backspace</v-icon>
            <span class="back-text">&nbsp; Atrás</span>
          </div>
        </v-col>
        <v-col class="text-right">
          <span class="current-step">Paso {{ currentStep }}</span>
          <span class="total-step">de {{ totalStep }}</span>
        </v-col>
      </v-row>
    </template>
    <template v-slot:content class="form-content">
      <div v-if="formComponent">
        <component :is="formComponent" :product="product" />
      </div>
    </template>
  </form-container>
</template>
<script>
import { mapActions } from 'vuex'
import FormContainer from '@/components/Form/FormContainer'
import ProductCategory from '@/components/Product/ProductCategory'
import ProductVitalInfoForm from '@/components/Product/ProductVitalInfoForm'
import ProductOffer from '@/components/Product/ProductOffer'
import ProductAdditionalInfo from '@/components/Product/ProductAdditionalInfo'
import ProductImage from '@/components/Product/ProductImage'
import { PRODUCT_TABS } from '@/forms/product'

export default {
  components: {
    FormContainer,
    ProductCategory,
    ProductOffer,
    ProductAdditionalInfo,
    ProductImage
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          category: null,
          variation_theme: null,
          variants: [],
          quantity_in_stock: null,
          price: null,
          condition: null,
          requires_formula: false,
          image_urls: []
        }
      }
    }
  },
  computed: {
    description () {
      return this.tabsConfig[this.tabIndex].description
    },
    title () {
      return this.tabsConfig[this.tabIndex].title
    },
    currentStep () {
      return this.tabIndex + 1
    },
    totalStep () {
      return PRODUCT_TABS.length
    },
    buttonText () {
      return this.tabIndex === PRODUCT_TABS.length - 1 ? 'Guardar' : 'Siguiente'
    },
    inputs () {
      const inputs = this.tabsConfig[this.tabIndex].inputs
      return inputs || undefined
    },
    useLoading () {
      return this.tabsConfig[this.tabIndex].useLoading
    }
  },
  data () {
    return {
      tabsConfig: PRODUCT_TABS,
      tabIndex: 0,
      formContents: {
        'product-category': ProductCategory,
        'product-vital-info': ProductVitalInfoForm,
        'product-offer': ProductOffer,
        'product-additional-info': ProductAdditionalInfo
      },
      formComponent: null
    }
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    ...mapActions('notification', ['showNotification']),
    setFormContent () {
      const formName = this.tabsConfig[this.tabIndex].name
      this.formComponent = this.formContents[formName]
    },
    validate () {
      const formName = this.tabsConfig[this.tabIndex].name
      const currentComponent = this.formContents[formName]
      if (!currentComponent) {
        return true
      }

      switch (formName) {
        case 'category':
          return this.product.category !== null
        default:
          return true
      }
    },
    setInputData (input) {
      const formName = this.tabsConfig[this.tabIndex].name
      const currentComponent = this.formContents[formName]
      if (currentComponent) {
        return
      }

      for (const key in input) {
        this.product[key] = input[key]
      }
    },
    back () {
      if (this.tabIndex === 0) {
        return
      }

      this.tabIndex--
      this.setFormContent()
    },
    next (input) {
      if (!this.validate()) {
        this.showNotification({ message: PRODUCT_TABS[this.tabIndex].errorMessage, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      if (this.tabIndex === this.totalStep - 1) {
        this.$emit('submit', this.product)
        return
      }

      this.setInputData(input)
      this.tabIndex++
      this.setFormContent()
    }
  },
  mounted () {
    this.getCategories()
    this.setFormContent()
  }
}
</script>
<style scoped>
.form-container{
  height: 100%;
}
.form-content{
  height: 100%;
}
.back-container {
  color: var(--app-button-bg-color);
  cursor: pointer;
}
.back-icon {
  color: var(--app-button-bg-color);
  margin-left: -0.625rem;
  width: 2.063rem;
  height: 2.063rem;
}
.back-text {
  font-size: 0.875rem;
  font-weight: bold;
}
.current-step {
  font-size: 0.875rem;
  font-weight: bold;
}
.total-step {
  font-size: 0.875rem;
  margin-left: 0.313rem;
}
</style>
