<template>
  <div>
    <v-row no-gutters>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
        <v-select
          class="form-input-right"
          v-model="product.variation_theme"
          :items="variantTypes"
          item-text="name"
          item-value="key"
          label="Tipo de variación"
          hide-details
          outlined
          dense
          @change="changedVariants"
        />
      </v-col>
    </v-row>

    <div class="offer-form-title">{{ formTitle }}</div>
    <div class="offer-form-images-text">
      Elija imágenes claras, informativas y atractivas. La primera imagen se colocará como portada del producto.
    </div>

    <v-row class="mt-5" no-gutters v-if="!hasVariants">
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
        <v-text-field
          class="form-input-right"
          v-model.number="product.quantity_in_stock"
          label="Cantidad"
          :rules="[ isRequiredNumeric ]"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
        <v-text-field
          class="form-input-left form-input-right"
          v-model.number="product.price"
          label="Precio"
          :rules="[ isRequired, isNumeric ]"
          outlined
          dense
        />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 4">
        <v-select
          class="form-input-left"
          v-model="product.condition"
          :items="conditions"
          item-text="name"
          item-value="key"
          label="Estado"
          :rules="[ isRequired ]"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12">
        <product-image class="mb-6 form-input" :product="product" />
      </v-col>
    </v-row>
    <product-variant-form :product="product" @allDeleted="allDeleted" v-else></product-variant-form>
  </div>
</template>
<script>
import { isRequired, isNumeric, isRequiredNumeric } from '@/shared/validations'
import ProductImage from '@/components/Product/ProductImage'
import ProductVariantForm from '@/components/Product/ProductVariantForm'
import { VARIANT_TYPES, VARIANT_TYPE_TITLE_FORM, VARIANT } from '@/forms/product'
import { PRODUCT_CONDITIONS } from '@/shared/statuses'
import { cloneObject } from '@/shared/clone'

export default {
  components: {
    ProductImage,
    ProductVariantForm
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          variation_theme: '',
          variants: [],
          quantity_in_stock: null,
          price: null,
          condition: null,
          image_urls: []
        }
      }
    }
  },
  data () {
    return {
      openModal: false,
      variantTypes: VARIANT_TYPES,
      conditions: PRODUCT_CONDITIONS,
      selectedVariationType: null,
      prevVariationType: null,
      prevVariants: []
    }
  },
  computed: {
    hasVariants () {
      const variant = this.product.variation_theme
      const variants = this.product.variants

      return !!variant && !!variants && variants.length > 0
    },
    formTitle () {
      const title = VARIANT_TYPE_TITLE_FORM[this.product.variation_theme]

      return title || 'Agregue la información de la oferta del producto'
    }
  },
  methods: {
    isRequired,
    isNumeric,
    isRequiredNumeric,
    changedVariants () {
      this.product.quantity_in_stock = null
      this.product.price = null
      this.product.condition = null
      this.product.image_urls = []

      this.product.variants = [cloneObject(VARIANT)]
    },
    allDeleted () {
      this.product.variation_theme = null
    }
  },
  mounted () {
    if (!this.product.variants) {
      this.product.variants = []
    }
  }
}
</script>
<style scoped>
.offer-form-title {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
}
.offer-form-images-text {
  font-size: 0.875rem;
  margin-bottom: 1.50rem;
}
.form-input-left {
  padding-left: 0.5rem;
}
.form-input-right {
  padding-right: 0.5rem;
}

@media only screen and (max-width: 37.5rem) {
  .form-input-left {
    padding-left: 0rem;
  }
  .form-input-right {
    padding-right: 0rem;
  }
}
</style>
