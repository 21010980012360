<template>
  <div>
    <div :class="{ 'images-container': hasUrls }">
      <div :class="[{ 'upload-container': !showErrorMessage, 'upload-container-error': showErrorMessage }]">
        <div :class="['text-container', { 'text-container-with-images': hasUrls }]">
          <v-row no-gutters class="text-center">
            <v-col cols="12">
              <v-icon class="camera-icon">mdi-camera-enhance</v-icon>
            </v-col>
            <v-col cols="12" class="text-content">
              <div class="upload-text">Agrega o arrastra tus fotos aquí</div>
            </v-col>
          </v-row>
        </div>
        <v-file-input class="input-file" :rules="[ isRequired ]" multiple @change="addImages($event)" hide-details></v-file-input>
      </div>
      <div class="product-image-container" v-for="(image, index) in images" :key="'product_image_'+index">
        <div class="product-image-content">
          <v-img class="product-image" :src="image" contain></v-img>
        </div>
        <div class="delete-image-container">
          <v-btn class="btn-icon-default" fab small raised @click="deleteImage(index)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <v-scroll-y-transition>
      <div class="v-text-field__details error-message-container" v-if="showErrorMessage">
        <div class="v-messages theme--light error--text">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">Agrega al menos una foto.</div>
          </div>
        </div>
      </div>
    </v-scroll-y-transition>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { cloneObject } from '@/shared/clone'
import { fileToBase64 } from '@/shared/convert'
import { newSha256 } from '@/shared/format'

export default {
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          image_urls: []
        }
      }
    }
  },
  computed: {
    hasUrls () {
      // eslint-disable-next-line no-unused-expressions
      this.refresh

      return !!this.product && !!this.product.image_urls && this.product.image_urls.length > 0
    },
    isRequired () {
      return () => {
        const hasUrls = this.hasUrls
        if (!hasUrls && !this.firstExecution) {
          this.showErrorMessage = true
        }

        this.firstExecution = false

        return hasUrls
      }
    }
  },
  data () {
    return {
      refresh: false,
      showErrorMessage: false,
      firstExecution: true,
      images: []
    }
  },
  methods: {
    ...mapActions('file', ['addUploadFile', 'deleteUploadFile']),
    async getFileName (file) {
      const result = await fileToBase64(file).catch(e => Error(e))
      if (result instanceof Error) {
        console.error('Error: ', result.message)
        return
      }

      const base64Parts = result.split(',')
      if (base64Parts.length !== 2) {
        console.error('Error: invalid base64 format')
        return
      }

      const name = await newSha256(base64Parts[1])

      return name
    },
    async addImages (files) {
      if (files.length === 0) {
        return
      }

      for (const file of files) {
        const name = await this.getFileName(file)
        if (!name) {
          return
        }

        this.addUploadFile({ name, file })

        this.images.push(URL.createObjectURL(file))
        this.product.image_urls.push(name)
        this.showErrorMessage = false
        this.refreshImages()
      }
    },
    deleteImage (index) {
      this.deleteUploadFile(this.product.image_urls[index])

      this.product.image_urls.splice(index, 1)
      if (this.product.image_urls.length === 0) {
        this.showErrorMessage = true
      }

      this.images.splice(index, 1)
      this.refreshImages()
    },
    refreshImages () {
      this.refresh = !this.refresh
    }
  },
  mounted () {
    if (!this.product.image_urls) {
      this.product.image_urls = []
    }

    this.images = cloneObject(this.product.image_urls)
  }
}
</script>
<style scoped>
.images-container {
  display: -webkit-box;
  max-width: 100%;
  overflow-x: scroll;
}
.upload-container {
  position: relative;
  border: 0.063rem dashed var(--app-button-bg-color);
  height: 7.5rem;
  border-radius: 0.188rem;
  font-size: 0.75rem;
  margin: 0.5rem 0 0.438rem 0;
}
.upload-container:hover {
  border: 0.125rem solid var(--app-button-bg-color);
}
.upload-container-error {
  position: relative;
  border: 0.063rem dashed #FF5252;
  height: 7.5rem;
  border-radius: 0.188rem;
  font-size: 0.75rem;
  margin: 0.5rem 0 0.438rem 0;
}
.upload-container-error:hover {
  border: 0.125rem solid #FF5252;
}
.text-container {
  display: flex;
  height: 100%;
  align-items: center;
}
.text-container-with-images {
  height: 7.5rem;
  width: 7.5rem;
}
.camera-icon {
  color: var(--app-button-bg-color);
}
.text-content {
  display: flex;
  justify-content: center;
}
.input-file{
  opacity: 0.0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
.input-file >>> .v-input__slot {
  opacity: 0.0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.input-file >>> .v-text-field__slot {
  width: 100%;
  height: 100%;
}
.input-file >>> .v-text-field__slot {
  position: absolute;
}
.input-file >>> .v-input__append-inner {
  width: 0;
  padding: 0;
  margin: 0;
}
.upload-text {
  max-width: 8.125rem;
  margin-top: 0.313rem;
}
.product-image-container {
  position: relative;
  box-shadow: 0 0.063rem 0.125rem 0 rgba(0,0,0,.15),0 0 0.063rem 0.063rem rgba(0,0,0,.05);
  width: 7.5rem;
  height: 7.5rem;
  margin: 0.5rem 0 0.438rem 1rem
}
.product-image-content {
  position: absolute;
  height: 100%;
  width: 100%;
}
.product-image {
  width: 7.5rem;
  height: 7.5rem;
}
.delete-image-container {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: white;
}
.product-image-container:hover .delete-image-container {
  display: flex;
}
.error-message-container {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
</style>
