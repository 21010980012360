var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly ? 12 : 6}},[_c('v-select',{attrs:{"items":_vm.requiresFormula,"item-text":"name","item-value":"key","label":"Maneja fórmula","outlined":"","dense":""},on:{"change":_vm.changedRequiresFormula},model:{value:(_vm.product.requires_formula),callback:function ($$v) {_vm.$set(_vm.product, "requires_formula", $$v)},expression:"product.requires_formula"}})],1)],1),(_vm.product.requires_formula)?_c('div',_vm._l((_vm.formulaValues),function(formula){return _c('v-row',{key:'formula_details_'+formula.name,staticClass:"formula-container",attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"formula-title"},[_vm._v(_vm._s(_vm.formulaDetails[formula.name].name))]),_c('div',[_vm._l((_vm.getProductFormulaValues(formula.name)),function(value,index){return _c('v-chip',{key:'modal_chip_' + formula.name + '_' + index,staticClass:"range-chip",attrs:{"close":"","small":""},on:{"click:close":function($event){return _vm.removeRange(formula.name, value)}}},[_c('span',[_vm._v(_vm._s(_vm.formatFormulaValue(value.min, formula.name)))]),(value.max)?_c('span',[_vm._v(", "+_vm._s(_vm.formatFormulaValue(value.max, formula.name)))]):_vm._e()])}),_c('v-chip',{staticClass:"range-chip",style:({
              color: 'var(--app-button-bg-color)'
            }),attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.openFormulaForm(formula.name)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" add ")]),_vm._v(" Agregar ")],1)],2)])],1)}),1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[(_vm.modalDetails)?_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.modalDetails.title))])]),_c('div',{staticClass:"modal-description"},[_vm._v(_vm._s(_vm.modalDetails.description))]),_c('v-card-text',[_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"modal-form",attrs:{"xl":"10","lg":"12","md":"12","sm":"12"}},[_c('product-formula-form',{attrs:{"modalDetails":_vm.modalDetails,"formulaValue":_vm.selectedFormulaValue}})],1)],1)],1),_c('v-card-actions',{staticClass:"text-center"},[_c('v-spacer'),_c('v-btn',{staticClass:"modal-button",style:({
            backgroundColor: 'transparent',
            color: 'rgba(0,0,0,0.6)',
            fontSize: '0.75rem',
          }),attrs:{"depressed":"","large":""},on:{"click":_vm.closeFormulaForm}},[_vm._v("Cancelar ")]),_c('v-btn',{staticClass:"modal-button",style:({
            backgroundColor: 'var(--app-button-bg-color)',
            color: '#FFFFFF',
            fontSize: '0.75rem',
          }),attrs:{"depressed":"","large":""},on:{"click":_vm.finish}},[_vm._v("Terminar ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }