<template>
  <v-form ref="form">
    <v-row no-gutters>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? 11 : 10">
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-autocomplete
              class="input-margin-right"
              v-model="formulaRange.min"
              :items="modalDetails.getOptions()"
              item-text="name"
              item-value="key"
              :label="modalDetails.singularName + ' inferior'"
              :rules="[ isRequiredNumeric ]"
              outlined
              dense
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6">
            <v-autocomplete
              class="input-margin-left"
              v-model="formulaRange.max"
              :items="modalDetails.getOptions()"
              item-text="name"
              item-value="key"
              :label="modalDetails.singularName + ' superior'"
              :rules="[ greaterThan ]"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="text-left">
            <v-chip class="range-chip" v-for="(value, index) in formulaValue.values" :key="'form_modal_chip_' + index" close small
              @click:close="removeRange(value)">
              <span>{{ formatFormulaValue(value.min) }}</span>
              <span v-if="value.max">, {{ formatFormulaValue(value.max) }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="add-container" :cols="$vuetify.breakpoint.smAndUp ? 1 : 2">
        <v-btn fab dark small outlined
          :style="{
            color: 'var(--app-button-bg-color)'
          }"
          @click="addRange">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { isRequiredNumeric } from '@/shared/validations'
import { getSphereValues, getCylinderValues, getAxisValues, getAddictionValues, getFormulaValue } from '@/shared/formula'

export default {
  props: {
    formulaValue: {
      required: true
    },
    modalDetails: {
      required: true
    }
  },
  data () {
    return {
      formulaRange: {
        min: null,
        max: null
      }
    }
  },
  methods: {
    isRequiredNumeric,
    getSphereValues,
    getCylinderValues,
    getAxisValues,
    getAddictionValues,
    greaterThan (value) {
      if (this.formulaRange.min === null) {
        return true
      }

      return value >= this.formulaRange.min || 'El valor debe ser mayor al minimo.'
    },
    formatFormulaValue (value) {
      if (value === 0) {
        return value
      }

      if (this.formulaValue.name === 'axis') {
        return value + '°'
      }

      const formattedValue = getFormulaValue(value, 2)

      return value > 0 ? '+' + formattedValue : formattedValue
    },
    addRange () {
      if (!this.$refs.form.validate()) {
        return
      }

      const range = { min: this.formulaRange.min }
      if (this.formulaRange.max && this.formulaRange.min !== this.formulaRange.max) {
        range.max = this.formulaRange.max
      }

      this.formulaValue.values.push(range)
      this.formulaRange = { min: null, max: null }
    },
    removeRange (value) {
      const index = this.formulaValue.values.indexOf(value)
      this.formulaValue.values.splice(index, 1)
    }
  }
}
</script>
<style scoped>
.input-margin-left {
  padding-left: 0.5rem;
}
.input-margin-right {
  padding-right: 0.5rem;
}
.range-chip {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}
@media only screen and (max-width: 37.5rem) {
  .input-margin-left {
    padding-left: 0rem;
  }
  .input-margin-right {
    padding-right: 0rem;
  }
  .add-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -1.5rem;
  }
}
</style>
