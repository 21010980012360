<template>
  <div>
    <v-text-field filled dense v-model="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Buscar"
      @keydown.enter.prevent="query"
      clearable
      hide-details />

    <div v-if="product.category">
      <v-list dense two-line>
        <v-subheader class="subheader-text">Categoriá seleccionada</v-subheader>
        <v-list-item class="selected-item">
          <v-list-item-avatar>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="product.category.name" class="selected-item-title"></v-list-item-title>
            <v-list-item-subtitle class="selected-item-item-subtitle">
              <div class="selected-item-item-subtitle-text" v-for="(path, index) in product.category.path_from_root" :key="path.category_id">
                <a @click="goToParentCategory(product.category.path_from_root, path.category_id)">{{path.name}}</a>
                <span v-if="index < product.category.path_from_root.length-1" class="selected-item-item-subtitle-separator">
                  >
                </span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="selected-note">
        La categoría debe estar relacionada con el título y la foto para que tus compradores encuentren tu producto. Si la categoría no es correcta, por favor vuelva a realizar la busquedad.
      </div>
    </div>
    <div v-else>
      <!-- result of the categories searched by name -->
      <v-list dense v-if="isSearchResult">
        <v-subheader class="subheader-text">Tu búsqueda coincide con estas categorías. ¿Alguna corresponde a tu producto?</v-subheader>
        <div class="category-container">
          <v-list-item v-for="category in results" :key="category.category_id" @click="selectCategory(category.category_id)" ripple>
            <v-list-item-avatar>
              <v-icon>mdi-tag</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="category.name"></v-list-item-title>
              <v-list-item-subtitle class="search-item-subtitle">
                <span v-for="(path, index) in category.path_from_root" :key="path.category_id">
                  {{path.name}}
                  <span v-if="index < category.path_from_root.length-1">
                    >
                  </span>
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="list-action">
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="v-list-item--link" @click="showAllCategories()">
            <v-list-item-content ripple>
              <v-list-item-title class="other-categories">Es de otra categoría</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <!-- result of manually searched categories -->
      <v-list dense v-else>
        <v-subheader class="subheader-text">
          <div v-if="isDetailResult && categoryHasParent">
            <span v-for="(path, index) in categoryDetail.path_from_root" :key="path.category_id">
              <a @click="goToParentCategory(categoryDetail.path_from_root, path.category_id)">{{path.name}}</a>
              <span v-if="index < categoryDetail.path_from_root.length-1" class="selected-item-item-subtitle-separator">
                >
              </span>
            </span>
          </div>
          <div v-else>
            <!-- <div class="mb-2" v-if="errorIsNotFound">Como no encontramos resultados para tu búsqueda, elige a qué categoría pertenece tu producto.</div> -->
            <div>Categorías</div>
          </div>
        </v-subheader>
        <div class="category-container">
          <v-list-item v-for="category in results" :key="category.category_id" @click="selectCategory(category.category_id)" ripple>
            <v-list-item-icon>
              <v-icon>mdi-tag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="category.name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="list-action">
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { STATUS_NOT_FOUND } from '@/shared/httpStatuses'

export default {
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          category: null
        }
      }
    }
  },
  data () {
    return {
      search: null,
      selectedCategory: null,
      resultType: 'all'
    }
  },
  computed: {
    ...mapState('category', ['categories', 'categoryDetail']),
    ...mapState('shared', ['error']),
    isSearchResult () {
      return this.resultType === 'search'
    },
    isDetailResult () {
      return this.resultType === 'detail'
    },
    errorIsNotFound () {
      return this.error && this.error.code === STATUS_NOT_FOUND
    },
    results () {
      switch (this.resultType) {
        case 'all':
          return this.categories ? this.categories.categories : []
        case 'search':
          return this.categories ? this.categories.categories : []
        case 'detail':
          return this.categoryDetail ? this.categoryDetail.children_categories : []
        default:
          return []
      }
    },
    categoryHasChildren () {
      const category = this.categoryDetail
      return category && category.children_categories && category.children_categories.length > 0
    },
    categoryHasParent () {
      const category = this.categoryDetail
      return category && category.path_from_root && category.path_from_root.length > 0
    }
  },
  methods: {
    ...mapActions('category', ['getCategories', 'getCategory']),
    async query () {
      if (!this.search) {
        return
      }

      const search = this.search.replace(/\s/g, '')
      if (search.length === 0) {
        return
      }

      this.product.category = null

      await this.getCategories(this.search)
      if (this.errorIsNotFound) {
        this.showAllCategories()
        return
      }

      this.resultType = 'search'
    },
    async showAllCategories () {
      this.product.category = null

      await this.getCategories()
      this.resultType = 'all'
    },
    async selectCategory (categoryID) {
      this.product.category = null

      await this.getCategory(categoryID)
      if (!this.categoryHasChildren) {
        this.product.category = this.categoryDetail
      }

      this.resultType = 'detail'
    },
    async goToParentCategory (pathFromRoot, categoryID) {
      let rootCategoryID = null

      for (const key in pathFromRoot) {
        const path = pathFromRoot[key]

        if (path.category_id === categoryID) {
          // -1 because we need to go to the category that contains the selected one
          const root = key - 1

          rootCategoryID = pathFromRoot[root] ? pathFromRoot[root].category_id : null
          break
        }
      }

      return rootCategoryID ? this.selectCategory(rootCategoryID) : this.showAllCategories()
    }
  }
}
</script>
<style scoped>
.category-container {
  max-height: 22.5rem;
  margin-top: 0.5rem;
  overflow-y: scroll;
}
.list-action {
  margin: 0;
}
.v-list-item {
  padding: 0 0.5rem;
}
.search-item-subtitle.v-list-item__subtitle {
  font-weight: 400;
  white-space: initial;
}
.subheader-text {
  font-size: 0.813rem;
}
.selected-item-title.v-list-item__title {
  font-size: 1rem;
  line-height: inherit;
}
.selected-item {
  border: solid 0.063rem #e7e7e7;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin: 0.5rem;
}
.selected-item-item-subtitle {
  margin-top: 0.25rem;
}
.selected-item-item-subtitle.v-list-item__subtitle {
  white-space: initial;
}
.selected-item-item-subtitle-text {
  display: inline-block;
  padding-top: 0.25rem;
}
.selected-item-item-subtitle-separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.selected-note {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0.125rem 0.5rem;
}
.other-categories {
  color: var(--app-button-bg-color);
}

@media only screen and (max-width: 37.5rem) {
  .category-container {
    max-height: 100%;
    overflow-y: scroll;
  }
  .selected-item-item-subtitle {
    margin-top: 0;
  }
}
</style>
