export const PRODUCT_TABS = [
  {
    name: 'product-category',
    title: 'Empecemos identificando la categoría de tu producto',
    description: 'Busca y selecciona la categoria que mas apropiada para tu producto',
    errorMessage: 'Por favor seleccione una categoria para continuar',
    useLoading: false
  },
  {
    name: 'product-vital-info',
    title: 'Ingresa la información vital del producto',
    description: 'Agrega la información del producto para que el cliente obtenga una mejor experiencia en las busquedas',
    useLoading: true
  },
  {
    name: 'product-offer',
    title: 'Ingresa la información de la oferta del producto',
    description: 'Agregue la información de la oferta del producto, si tu producto maneja variantes, por ejemplo un producto con diferentes colores o tamaños, por favor modificar el campo tipo de variación según la variante deseada',
    useLoading: true
  },
  {
    name: 'product-additional-info',
    title: 'Ingresa la información adicional del producto',
    description: 'Agregue la información adicional del producto. Si su producto maneja formula aqui podra asociar rangos de disponibilidad segun las formulas disponibles',
    useLoading: true
  }
]

export const VARIANT_TYPES = [
  {
    key: null,
    name: 'Sin variantes'
  },
  {
    key: 'color',
    name: 'Color'
  },
  {
    key: 'material',
    name: 'Material'
  },
  {
    key: 'size',
    name: 'Talla'
  }
]

export const VARIANT_TYPE_NAME = {
  color: 'Color',
  material: 'Material',
  size: 'Talla'
}

export const VARIANT_TYPE_TITLE_FORM = {
  color: 'Ingresa los colores del producto',
  material: 'Ingresa los materiales del producto',
  size: 'Ingresa los tallas del producto'
}

export const VARIANT = {
  bar_code: '',
  name: '',
  condition: '',
  quantity_in_stock: '',
  price: '',
  image_urls: []
}
